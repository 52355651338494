import { getShouldViewKBArticleExpanded } from '../../kb-article/kbArticleSelectors';
import { setViewExpanded } from '../../kb-article/kbArticleSlice';
import { getIsUngatedForUpdatedUI } from '../../widget-data/selectors/widgetDataSelectors';
import { DEFAULT_WIDGET_SIZE, UPDATED_WIDGET_SIZE, setWidgetSize } from '../../widget-size/widgetSizeSlice';
export function toggleWidgetSizeForKBArticle(browserWindowHeight) {
  return (dispatch, getState) => {
    const isUngatedForUpdatedUI = getIsUngatedForUpdatedUI(getState());
    const viewExpanded = getShouldViewKBArticleExpanded(getState());
    if (viewExpanded) {
      dispatch(setWidgetSize(isUngatedForUpdatedUI ? UPDATED_WIDGET_SIZE : DEFAULT_WIDGET_SIZE));
    } else {
      dispatch(setWidgetSize({
        width: 640,
        height: browserWindowHeight
      }));
    }
    dispatch(setViewExpanded(!viewExpanded));
  };
}