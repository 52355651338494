import { createSlice } from '@reduxjs/toolkit';
import { FADE } from '../constants/constants';
const initialState = FADE;
const currentAnimationSlice = createSlice({
  name: 'currentAnimation',
  initialState,
  reducers: {
    updateCurrentAnimation: (__, action) => {
      return action.payload;
    }
  }
});
export const {
  updateCurrentAnimation
} = currentAnimationSlice.actions;
export default currentAnimationSlice.reducer;