//@ts-expect-error untyped-file
import { defaultMessageReceived } from '../../actions/defaultMessageReceived';
//@ts-expect-error untyped-file
import { getCurrentThreadHistoryEntry } from '../../thread-history/selectors/getCurrentThreadHistoryEntry';
//@ts-expect-error untyped-file
import { isCommonMessageFormat } from 'conversations-message-history/common-message-format/operators/cmfComparators';
import { getCompletedMessageId, getPartialOrdinal, getCompletedMessageTimestamp } from 'conversations-message-history/partial-message/operators/partialMessageGetters';
import { getTimestamp
//@ts-expect-error untyped-file
} from 'conversations-message-history/common-message-format/operators/commonMessageFormatGetters';
import { MESSAGE_RESULTS } from 'conversations-message-history/thread-history/constants/keyPaths';
export const partialMessageReceived = ({
  message,
  channel,
  threadId,
  publishContext
}) => (dispatch, getState) => {
  const currentState = getState();
  const existingMessage = getCurrentThreadHistoryEntry(currentState).getIn(['data', ...MESSAGE_RESULTS, message.get('completedMessageId')]);
  if (existingMessage && isCommonMessageFormat(existingMessage)) {
    // Don't overwrite common messages with partial messages
    return;
  }
  if (existingMessage && (getPartialOrdinal(existingMessage) > getPartialOrdinal(message) || getTimestamp(existingMessage) > getTimestamp(message))) {
    return;
  }
  const stubMessage = message.set('id', getCompletedMessageId(message)).set('timestamp', getCompletedMessageTimestamp(message));
  dispatch(defaultMessageReceived(stubMessage, channel, threadId, publishContext));
};