import { createSlice } from '@reduxjs/toolkit';
import { GET_WIDGET_DATA_SUCCEEDED } from '../constants/VisitorActionTypes';
export const DEFAULT_WIDGET_SIZE = {
  width: 376,
  height: 530
};
export const UPDATED_WIDGET_SIZE = {
  width: 416,
  height: 700
};
const initialState = {
  size: DEFAULT_WIDGET_SIZE
};
const widgetSizeSlice = createSlice({
  name: 'widgetSize',
  initialState,
  reducers: {
    setWidgetSize(state, action) {
      state.size = action.payload;
    },
    resetWidgetSize(state) {
      state.size = DEFAULT_WIDGET_SIZE;
    }
  },
  extraReducers: builder => {
    builder.addCase(GET_WIDGET_DATA_SUCCEEDED, (state, action) => {
      const isUngatedForUpdatedUI = action.payload.get('gates').get('Conversations:UpdatedUI');
      state.size = isUngatedForUpdatedUI ? UPDATED_WIDGET_SIZE : DEFAULT_WIDGET_SIZE;
    });
  }
});
export const {
  setWidgetSize,
  resetWidgetSize
} = widgetSizeSlice.actions;
export default widgetSizeSlice.reducer;