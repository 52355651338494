import { updateView } from '../../current-view/actions/updateView';
import { KNOWLEDGE_BASE_ARTICLE } from '../../current-view/constants/views';
import { getShouldViewKBArticleExpanded } from '../../kb-article/kbArticleSelectors';
import { setKBArticle, setOriginViewContext } from '../../kb-article/kbArticleSlice';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
import { setWidgetSize } from '../../widget-size/widgetSizeSlice';
export function navigateToKnowledgeBaseArticle(articleData, browserWindowHeight, originViewContext) {
  return (dispatch, getState) => {
    const shouldViewExpanded = getShouldViewKBArticleExpanded(getState());
    dispatch(setKBArticle(articleData));
    dispatch(setOriginViewContext(originViewContext));
    if (shouldViewExpanded) {
      dispatch(setWidgetSize({
        width: 640,
        height: browserWindowHeight
      }));
    }
    dispatch(updateView(KNOWLEDGE_BASE_ARTICLE));
    dispatch(trackInteraction('widget-interaction', {
      action: 'view KB article in widget'
    }));
  };
}