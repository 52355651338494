export const FILES = 'FILES';
export const EMAIL_METADATA = 'EMAIL_METADATA';
export const QUICK_REPLIES = 'QUICK_REPLIES';
export const INLINE_IMAGES = 'INLINE_IMAGES';
export const CALL_METADATA = 'CALL_METADATA';
export const MESSAGE_POSITION_DESCRIPTOR = 'MESSAGE_POSITION_DESCRIPTOR';
export const LOCATION = 'LOCATION';
export const CONTACT = 'CONTACT';
export const UNSUPPORTED_CONTENT = 'UNSUPPORTED_CONTENT';
export const MESSAGE_HEADER = 'MESSAGE_HEADER';
export const CITATION = 'CITATION';
export const MENTIONS = 'MENTIONS';