import { combineReducers } from 'redux';
import { pubSubClient } from 'conversations-internal-pub-sub/redux/reducers/pubSubClient';
import { subscriptions } from 'conversations-internal-pub-sub/redux/reducers/subscriptions';

// @ts-expect-error Untyped Import
import widgetData from '../widget-data/reducers/widgetData';
// @ts-expect-error Untyped Import
import visitorIdentity from '../visitor-identity/reducers/visitorIdentity';
// @ts-expect-error Untyped Import
import widgetUi from '../widget-ui/reducers/widgetUi';
// @ts-expect-error Untyped Import
import emailCapture from '../email-capture/emailCaptureReducer';
// @ts-expect-error Untyped Import
import availabilityMessageTimeouts from '../availability/reducers/availabilityMessageTimeouts';
// @ts-expect-error Untyped Import
import responders from '../responders/reducers/responders';
// @ts-expect-error Untyped Import
import visitorThreadHistories from '../thread-histories/reducers/visitorThreadHistories';
// @ts-expect-error Untyped Import
import typingStates from '../typing-indicators/reducers/typingStatesReducer';
// @ts-expect-error Untyped Import
import fileUploads from '../file-uploads/reducers/fileUploads';
// @ts-expect-error Untyped Import
import fileUploadsErrors from '../file-uploads/reducers/fileUploadsErrors';
// @ts-expect-error Untyped Import
import resolvedAttachments from '../resolved-attachments/reducers/resolvedAttachments';
// @ts-expect-error Untyped Import
import gdpr from '../gdpr/reducers/gdpr';
// @ts-expect-error Untyped Import
import currentView from '../current-view/reducers/currentView';
// @ts-expect-error Untyped Import
import initialMessageBubbleVisible from '../initial-message-bubble/reducers/initialMessageBubbleVisible';
// @ts-expect-error Untyped Import
import timeOnPageTrigger from '../time-on-page-trigger/reducers/timeOnPageTrigger';
// @ts-expect-error Untyped Import
import threads from '../threads/reducers/threads';
// @ts-expect-error Untyped Import
import cookieBannerOnExitVisible from '../visitor-identity/reducers/cookieBannerOnExitVisible';
import visitorIdentificationEnabled from '../visitor-identity/reducers/visitorIdentificationEnabled';
// @ts-expect-error Untyped Import
import selectedThreadId from '../selected-thread/reducers/selectedThreadId';
// @ts-expect-error Untyped Import
import unpublishedMessages from '../pubsub/reducers/unpublishedMessages';
// @ts-expect-error Untyped Import
import { stagedThread } from '../thread-create/reducers/stagedThread';
import widgetInputFocusStatus from '../visitor-widget/reducers/widgetInputFocusStatus';

// Migrated to Redux Toolkit
import messageEditorStaging from '../message-editor/reducers/messageEditorStaging';
import feedbackSurveyEnabled from '../feedback-survey/feedbackSurveyEnabledSlice';
import widgetSize from '../widget-size/widgetSizeSlice';
import clientData from '../client-data/reducers/clientData';
import kbArticle from '../kb-article/kbArticleSlice';
import currentAnimation from '../animation/reducers/currentAnimation';
export default combineReducers({
  typingStates,
  availabilityMessageTimeouts,
  clientData,
  cookieBannerOnExitVisible,
  currentView,
  emailCapture,
  fileUploads,
  fileUploadsErrors,
  gdpr,
  initialMessageBubbleVisible,
  kbArticle,
  messageEditorStaging,
  pubSubClient,
  resolvedAttachments,
  responders,
  selectedThreadId,
  stagedThread,
  subscriptions,
  threadHistories: visitorThreadHistories,
  threads,
  timeOnPageTrigger,
  unpublishedMessages,
  visitorIdentity,
  widgetData,
  widgetInputFocusStatus,
  widgetUi,
  feedbackSurveyEnabled,
  visitorIdentificationEnabled,
  widgetSize,
  currentAnimation
});