'use es6';

import PortalIdParser from 'PortalIdParser';
import noAuthHttp from '../../http/noAuthApiClient';
export function createVisitorThread({
  sessionId,
  messageId,
  hubspotUtk,
  messagesPageUri,
  visitorThreadInitialHistory,
  zoneId
}, shouldUseNewEndpoint = false) {
  const url = shouldUseNewEndpoint ? `livechat-public/v1/thread/visitor/create` : `livechat-public/v1/thread/visitor`;
  const data = shouldUseNewEndpoint ? {
    visitorMessage: visitorThreadInitialHistory[visitorThreadInitialHistory.length - 1],
    expectedInitialHistory: visitorThreadInitialHistory.slice(0, -1).map(message => message.richText || message.text)
  } : {
    visitorThreadInitialHistory
  };
  return noAuthHttp.post(url, {
    query: {
      sessionId,
      welcomeMessageId: messageId,
      hubspotUtk,
      portalId: PortalIdParser.get(),
      expectedResponseType: 'WRAPPER_V2',
      zoneId
    },
    data,
    headers: {
      'X-HubSpot-Messages-Uri': messagesPageUri
    }
  });
}