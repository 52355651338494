import { createContainer } from 'usage-tracker-multi';
import PortalIdParser from 'PortalIdParser';
import { PAGEVIEW_SAMPLE_PERCENT } from '../constants/pageViewSamplePercent';
import events from 'conversations-visitor-ui/events.yaml'; // eslint-disable-line

const {
  createTracker
} = createContainer({
  storeUuidOnUrl: false
});
const makePublicProperties = ({
  messagesUtk
}) => ({
  hubId: PortalIdParser.get(),
  // HACK!!! internally, usage-tracker splits the hstc on "." and takes the second item
  hstc: messagesUtk ? `.${messagesUtk}` : null,
  isIncludedInPageViewSample: Math.random() < PAGEVIEW_SAMPLE_PERCENT
});
const makeAppProperties = () => ({
  namespace: 'conversations-visitor-ui',
  hubId: PortalIdParser.get(),
  isIncludedInPageViewSample: false
});
export function makeUsageTracker({
  messagesUtk,
  inApp53 = false
}) {
  return createTracker({
    events,
    trackerType: inApp53 ? 'authed' : messagesUtk ? 'public' : 'anonymous',
    properties: inApp53 ? makeAppProperties() : makePublicProperties({
      messagesUtk
    })
  });
}