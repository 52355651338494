// @ts-expect-error untyped file
import { publishVisitorMessage } from '../../pubsub/actions/publishVisitorMessage';
//@ts-expect-error untyped-file
import { escape } from 'conversations-message-history/util/escape';
import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
// @ts-expect-error untyped file
import { toggleOpen } from '../../actions/WidgetActions';
//@ts-expect-error untyped-file
import autolinker from 'autolinker';
import Raven from 'raven-js';
import { getKnowledgeBaseEnabled } from '../../selectors/widgetDataSelectors/getKnowledgeBaseEnabled';
import { loadStagedThread } from '../../navigation/actions/loadStagedThread';
import { SCALE, FADE } from '../../animation/constants/constants';
import { updateCurrentAnimation } from '../../animation/reducers/currentAnimation';
export function publishMessageFromAIWelcomeMessageTextInput(text) {
  const richText = `<div>${autolinker.link(escape(text), {
    stripPrefix: false
  })}</div>`.replace(/\n/g, '<br>');
  return (dispatch, getState) => {
    dispatch(updateCurrentAnimation(SCALE));
    if (getKnowledgeBaseEnabled(getState())) {
      dispatch(loadStagedThread());
    }
    dispatch(publishVisitorMessage({
      text,
      richText
    })).then(() => {
      dispatch(toggleOpen({
        isOpened: true,
        isUser: true
      }));
      dispatch(trackInteraction('widget-interaction', {
        action: 'sent message from AI welcome message input'
      }));
      dispatch(updateCurrentAnimation(FADE));
    }).catch(error => {
      Raven.captureException(error);
    });
  };
}