import { DEFAULT_FONT_FAMILY } from '../theme/fontConstants';
const HELVETICA = 'helvetica';
export function getFontWithFallback(fontFamily, fallback) {
  if (!fontFamily || fontFamily === `${DEFAULT_FONT_FAMILY}` || (fontFamily === null || fontFamily === void 0 ? void 0 : fontFamily.toLowerCase()) === HELVETICA) {
    return DEFAULT_FONT_FAMILY;
  } else if (Array.isArray(fallback)) {
    return fallback.length ? [fontFamily, ...fallback].join(', ') : `${fontFamily}, ${DEFAULT_FONT_FAMILY}`;
  } else {
    return `${fontFamily}, ${fallback || DEFAULT_FONT_FAMILY}`;
  }
}