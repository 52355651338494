import { trackInteraction } from '../../usage-tracking/actions/trackInteraction';
import { trackMessageSentMetric } from '../../usage-tracking/utils/trackMetric';
export const trackSendMessage = ({
  threadId
}) => dispatch => {
  const eventProps = {};
  if (typeof threadId === 'number') {
    eventProps.threadId = threadId;
  }
  trackMessageSentMetric();
  dispatch(trackInteraction('send-message', eventProps));
};