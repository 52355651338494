import { updateView } from '../../current-view/actions/updateView';
import { THREAD_VIEW } from '../../current-view/constants/views';
import { getOriginViewContext } from '../../kb-article/kbArticleSelectors';
import { selectThread } from '../../selected-thread/actions/selectThread';
import { getIsUngatedForUpdatedUI } from '../../widget-data/selectors/widgetDataSelectors';
import { DEFAULT_WIDGET_SIZE, UPDATED_WIDGET_SIZE, setWidgetSize } from '../../widget-size/widgetSizeSlice';
// @ts-expect-error untyped module
import { getCurrentThreadId } from '../../thread-history/selectors/getCurrentThreadId';
export function restorePreviousViewFromKBArticle() {
  return (dispatch, getState) => {
    const isUngatedForUpdatedUI = getIsUngatedForUpdatedUI(getState());
    const {
      view
    } = getOriginViewContext(getState());
    dispatch(setWidgetSize(isUngatedForUpdatedUI ? UPDATED_WIDGET_SIZE : DEFAULT_WIDGET_SIZE));
    if (view === THREAD_VIEW) {
      dispatch(selectThread(getCurrentThreadId(getState())));
    }
    dispatch(updateView(view));
  };
}