import { createSelector } from '@reduxjs/toolkit';

// @ts-expect-error Untyped import
import { findAssignedResponder } from '../../threads/operators/findAssignedResponder';
// @ts-expect-error Untyped import
import { getSelectedThread } from '../../selected-thread/selectors/getSelectedThread';
// @ts-expect-error Untyped import
import { getAgentRespondersList } from '../../responders/selectors/getAgentRespondersList';
import { getWidgetBotResponder } from '../../selectors/widgetDataSelectors/getWidgetBotResponder';
export const getAssignedResponderInWidget = createSelector([getAgentRespondersList, getSelectedThread, getWidgetBotResponder], (responders, selectedThread, botResponder) => findAssignedResponder({
  thread: selectedThread,
  responders,
  botResponder
}));