import { fromJS } from 'immutable';
import compose from 'transmute/compose';
import getIn from 'transmute/getIn';
import { generateUuid } from '../../util/generateUuid';
import { generateUniqueClientTimestamp } from '../../util/timestamps';
import { STATUS } from '../../common-message-format/constants/keyPaths';
import PartialMessage from '../records/PartialMessage';
// @ts-expect-error module not typed
import { buildStatus } from '../../common-message-format/operators/buildStatus';
import { getId, getTimestamp
// @ts-expect-error module not typed
} from '../../common-message-format/operators/commonMessageFormatGetters';
import { getGenericChannelId, getCompletedMessageId, getCompletedMessageTimestamp, getPartialOrdinal } from '../operators/partialMessageGetters';
import { setId, setStatus, setTimestamp
// @ts-expect-error module not typed
} from '../../common-message-format/operators/commonMessageFormatSetters';
import { setMessageDirection, setRecipients, setSenders, setGenericChannelId } from '../../common-message-format/operators/commonMessageSetters';
import { buildRecipients } from '../../common-message-format/operators/buildRecipients';
import { buildSenders } from '../../common-message-format/operators/buildSenders';
import { setCompletedMessageId, setCompletedMessageTimestamp, setPartialOrdinal } from './partialMessageSetters';
export const buildPartialMessage = (props = {}) => {
  const status = buildStatus(getIn(STATUS, props));
  const recipients = buildRecipients(props.recipients);
  const senders = buildSenders(props.senders);
  const messageDirection = props.direction || '';
  const id = getId(props) || generateUuid();
  const genericChannelId = getGenericChannelId(props) || null;
  const completedMessageId = getCompletedMessageId(props);
  const timeStamp = getTimestamp(props) || generateUniqueClientTimestamp('buildPartialMessage-timestamp');
  const completedMessageTimestamp = getCompletedMessageTimestamp(props) || getTimestamp(props) || generateUniqueClientTimestamp('buildPartialMessage-timestamp');
  const partialOrdinal = getPartialOrdinal(props);
  return compose(setId(id), setCompletedMessageId(completedMessageId), setPartialOrdinal(partialOrdinal), setStatus(status), setTimestamp(timeStamp), setCompletedMessageTimestamp(completedMessageTimestamp), setRecipients(recipients), setSenders(senders), setMessageDirection(messageDirection), setGenericChannelId(genericChannelId))(new PartialMessage(fromJS(props)));
};