import { OLAF, OZ, BATTLESHIP, OBSIDIAN, CANDY_APPLE, KOALA, HEFFALUMP, GYPSUM, CHART_GREEN, CHART_ORANGE, CHART_RED, BUTTON_DISABLED_TEXT, FLINT } from 'HubStyleTokens/colors';
export const WHITE = OLAF;
export const GREEN = OZ;
export const GREY = BATTLESHIP;
export const DEFAULT_PRIMARY_COLOR = OBSIDIAN;
export const DEFAULT_TEXT_COLOR = OBSIDIAN;
export const DEFAULT_ERROR_TEXT_COLOR = CANDY_APPLE;
export const DISABLED_BACKGROUND_COLOR = KOALA;
export const DISABLED_TEXT_COLOR = BUTTON_DISABLED_TEXT;
export const DEFAULT_HELP_TEXT_COLOR = HEFFALUMP;
export const DEFAULT_PLACEHOLDER_TEXT_COLOR = '#7b98b6';
export const DEFAULT_INPUT_BORDER_COLOR = BATTLESHIP;
export const UPDATED_INPUT_BORDER_COLOR = FLINT;
export const DEFAULT_INPUT_BACKGROUND_COLOR = GYPSUM;
export const DEFAULT_HAPPY_COLOR = CHART_GREEN;
export const DEFAULT_NEUTRAL_COLOR = CHART_ORANGE;
export const DEFAULT_SAD_COLOR = CHART_RED;