'use es6';

import { useMemo, useRef, useEffect } from 'react';
import { useOverlayTriggerState } from '@react-stately/overlays';
const $8796f90736e175cb$var$TOOLTIP_DELAY = 1500; // this seems to be a 1.5 second delay, check with design
const $8796f90736e175cb$var$TOOLTIP_COOLDOWN = 500;
let $8796f90736e175cb$var$tooltips = {};
let $8796f90736e175cb$var$tooltipId = 0;
let $8796f90736e175cb$var$globalWarmedUp = false;
let $8796f90736e175cb$var$globalWarmUpTimeout = null;
let $8796f90736e175cb$var$globalCooldownTimeout = null;
function $8796f90736e175cb$export$4d40659c25ecb50b(props = {}) {
  let {
    delay = $8796f90736e175cb$var$TOOLTIP_DELAY
  } = props;
  let {
    isOpen: isOpen,
    open: open,
    close: close
  } = useOverlayTriggerState(props);
  let id = useMemo(() => `${++$8796f90736e175cb$var$tooltipId}`, []);
  let closeTimeout = useRef();
  let ensureTooltipEntry = () => {
    $8796f90736e175cb$var$tooltips[id] = hideTooltip;
  };
  let closeOpenTooltips = () => {
    for (let hideTooltipId in $8796f90736e175cb$var$tooltips) if (hideTooltipId !== id) {
      $8796f90736e175cb$var$tooltips[hideTooltipId](true);
      delete $8796f90736e175cb$var$tooltips[hideTooltipId];
    }
  };
  let showTooltip = () => {
    clearTimeout(closeTimeout.current);
    closeTimeout.current = null;
    closeOpenTooltips();
    ensureTooltipEntry();
    $8796f90736e175cb$var$globalWarmedUp = true;
    open();
    if ($8796f90736e175cb$var$globalWarmUpTimeout) {
      clearTimeout($8796f90736e175cb$var$globalWarmUpTimeout);
      $8796f90736e175cb$var$globalWarmUpTimeout = null;
    }
    if ($8796f90736e175cb$var$globalCooldownTimeout) {
      clearTimeout($8796f90736e175cb$var$globalCooldownTimeout);
      $8796f90736e175cb$var$globalCooldownTimeout = null;
    }
  };
  let hideTooltip = immediate => {
    if (immediate) {
      clearTimeout(closeTimeout.current);
      closeTimeout.current = null;
      close();
    } else if (!closeTimeout.current) closeTimeout.current = setTimeout(() => {
      closeTimeout.current = null;
      close();
    }, $8796f90736e175cb$var$TOOLTIP_COOLDOWN);
    if ($8796f90736e175cb$var$globalWarmUpTimeout) {
      clearTimeout($8796f90736e175cb$var$globalWarmUpTimeout);
      $8796f90736e175cb$var$globalWarmUpTimeout = null;
    }
    if ($8796f90736e175cb$var$globalWarmedUp) {
      if ($8796f90736e175cb$var$globalCooldownTimeout) clearTimeout($8796f90736e175cb$var$globalCooldownTimeout);
      $8796f90736e175cb$var$globalCooldownTimeout = setTimeout(() => {
        delete $8796f90736e175cb$var$tooltips[id];
        $8796f90736e175cb$var$globalCooldownTimeout = null;
        $8796f90736e175cb$var$globalWarmedUp = false;
      }, $8796f90736e175cb$var$TOOLTIP_COOLDOWN);
    }
  };
  let warmupTooltip = () => {
    closeOpenTooltips();
    ensureTooltipEntry();
    if (!isOpen && !$8796f90736e175cb$var$globalWarmUpTimeout && !$8796f90736e175cb$var$globalWarmedUp) $8796f90736e175cb$var$globalWarmUpTimeout = setTimeout(() => {
      $8796f90736e175cb$var$globalWarmUpTimeout = null;
      $8796f90736e175cb$var$globalWarmedUp = true;
      showTooltip();
    }, delay);else if (!isOpen) showTooltip();
  };
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      clearTimeout(closeTimeout.current);
      let tooltip = $8796f90736e175cb$var$tooltips[id];
      if (tooltip) delete $8796f90736e175cb$var$tooltips[id];
    };
  }, [id]);
  return {
    isOpen: isOpen,
    open: immediate => {
      if (!immediate && delay > 0 && !closeTimeout.current) warmupTooltip();else showTooltip();
    },
    close: hideTooltip
  };
}
export { $8796f90736e175cb$export$4d40659c25ecb50b as useTooltipTriggerState };