import { createSelector } from '@reduxjs/toolkit';
import { getWelcomeMessage } from '../../selectors/widgetDataSelectors/getWelcomeMessage';
import { getGates, getLanguage, getSearchableKnowledgeBaseIds as getSearchableKnowledgeBaseIdsOperator } from '../operators/widgetDataGetters';
import { getLatestWidgetData } from './getLatestWidgetData';
export const getWidgetDataLanguage = createSelector(getLatestWidgetData, widgetData => getLanguage(widgetData));
export const getSearchableKnowledgeBaseIds = createSelector(getWelcomeMessage, welcomeMessage => getSearchableKnowledgeBaseIdsOperator(welcomeMessage));
export const getWidgetDataGates = createSelector(getLatestWidgetData, widgetData => getGates(widgetData));
export const getIsUngatedForUpdatedUI = createSelector(getWidgetDataGates, gates => gates && gates.get('Conversations:UpdatedUI'));
export const getIsUngatedForAIWelcomeMessage = getIsUngatedForUpdatedUI;
export const getIsUngatedForUpdatedThreadCreationEndpoint = createSelector(getWidgetDataGates, gates => gates === null || gates === void 0 ? void 0 : gates.get('Conversations:ThreadCreateUpdate'));
export const getIsUngatedForFontCustomization = createSelector(getWidgetDataGates, gates => gates === null || gates === void 0 ? void 0 : gates.get('Conversations:LiveChatFontCustomization'));