'use es6';

import bootstrapWidget from '../../actions/bootstrapWidget';
import { initializeI18n } from '../../utils/initializeI18n';
import { fetchFeedbackSurvey } from '../../feedback-survey/feedbackSurveyEnabledSlice';
import { fetchVisitorThreads } from '../../threads/actions/ThreadActions';
import { getShouldFetchInitialVisitorThreads } from '../../threads/selectors/getShouldFetchInitialVisitorThreads';
import { navigateToInitialView } from '../../navigation/actions/navigateToInitialView';
import { setThreadsSuccess } from '../../threads/actions/setThreadsSuccess';
export const handleReceiveWidgetData = ({
  data
}) => (dispatch, getState) => {
  if (data) {
    return initializeI18n({
      data
    }).then(() => {
      const {
        message: {
          surveyId
        },
        sessionId
      } = data;
      if (surveyId !== null) {
        dispatch(fetchFeedbackSurvey(surveyId, sessionId));
      }
      dispatch(bootstrapWidget(data));
      const shouldFetchVisitorThreads = getShouldFetchInitialVisitorThreads(getState());
      if (shouldFetchVisitorThreads) {
        dispatch(fetchVisitorThreads()).then(() => {
          dispatch(setThreadsSuccess());
          dispatch(navigateToInitialView());
        }).catch(err => {
          throw err;
        });
      } else {
        dispatch(setThreadsSuccess());
        dispatch(navigateToInitialView());
      }
    }, () => {
      dispatch(bootstrapWidget(data));
    });
  }
  return null;
};