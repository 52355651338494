import { fromJS, List } from 'immutable';
import reduce from 'transmute/reduce';
import { generateUuid } from '../../util/generateUuid';
import { generateUniqueClientTimestamp } from '../../util/timestamps';
import EngagementDetailsRecord from '../records/EngagementDetailsRecord';
import { buildSenders } from '../../common-message-format/operators/buildSenders';
export const buildEngagementDetailsMessage = (attrs = {}) => {
  const {
    attachments = [],
    senders = []
  } = attrs;
  const attachmentsList = reduce(List(), (attachmentList, attachment) => {
    return attachmentList.push(fromJS(attachment));
  }, attachments);
  const id = attrs.id || generateUuid();
  const timestamp = attrs.timestamp || generateUniqueClientTimestamp('buildEngagementMessage-timestamp');
  const sendersList = buildSenders(senders);
  const baseEngagementMessage = EngagementDetailsRecord(fromJS(attrs));
  return baseEngagementMessage.merge({
    senders: sendersList,
    timestamp,
    attachments: attachmentsList,
    id
  });
};