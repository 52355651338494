'use es6';

import * as ActionTypes from '../../constants/VisitorActionTypes';
import { TOGGLE_INITIAL_MESSAGE_BUBBLE } from '../../initial-message-bubble/constants/initialMessageBubbleActionTypes';
import WidgetUi from '../records/WidgetUi';
import { parseStringBoolean } from '../../utils/parseStringBoolean';
const {
  TOGGLE_OPEN,
  RECEIVED_WIDGET_SHELL_DATA,
  TRACK_USER_INTERACTION
} = ActionTypes;
const parseShellData = data => ({
  mobile: parseStringBoolean(`${data.mobile}`),
  open: parseStringBoolean(`${data.open}`),
  hideWelcomeMessage: parseStringBoolean(`${data.hideWelcomeMessage}`),
  domain: data.domain,
  startOpen: data.startOpen,
  url: data.url,
  userInteractedWithWidget: false,
  isEmbeddedInProduct: parseStringBoolean(`${data.inApp53}`),
  isIOSMobile: parseStringBoolean(`${data.isIOSMobile}`)
});
export default function widgetUi(state = WidgetUi(), action) {
  switch (action.type) {
    case TOGGLE_INITIAL_MESSAGE_BUBBLE:
      if (!action.payload.visible && action.payload.closedByUser) {
        return state.set('startOpen', false).set('hideWelcomeMessage', true);
      }
      return state;
    case RECEIVED_WIDGET_SHELL_DATA:
      return state.merge(parseShellData(action.payload));
    case TOGGLE_OPEN:
      if (!action.payload.isOpened && action.payload.isUser) {
        state = state.set('startOpen', false).set('hideWelcomeMessage', true);
      }
      return state.set('open', action.payload.isOpened);
    case TRACK_USER_INTERACTION:
      return state.set('userInteractedWithWidget', true);
    default:
      return state;
  }
}