import { getFullUrl } from 'hubspot-url-utils';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
export const NETWORK_CHECK_TIMEOUT_MS = 5000;
export const checkNetwork = () => {
  return noAuthApiClient.get(`${getFullUrl('app')}/network-check/is-the-internet-up.txt`, {
    timeout: NETWORK_CHECK_TIMEOUT_MS,
    query: {
      rnd: Math.random()
    }
  }).then(() => ({
    online: true
  }), error => ({
    online: false,
    error
  }));
};